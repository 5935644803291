import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"


class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Moon and Sun Kickstarter Backer Reward" />
            <img src={'/moon-sun-ks-thumbnail-sm.jpg'}/>
            <p>Click here to download the ebook of Moon and Sun:</p>
            <h1 style={{marginTop: '0px'}}x><a href={'/Moon%20and%20Sun.pdf'}>DOWNLOAD</a></h1>
            <p>This ebook in pdf format is compatible with all devices, and can be imported into Kindle and other ebook software.</p>
            <p>This ebook is provided as a gift for our KickStarter patrons only. Please do not share. The ebook will be available commercially when the hardcover book is released publicly this summer.</p>
            <p>Thank you for your kind support of this project. </p>

            <h2> <a href="http://eepurl.com/gE3piX">Sign up here for my newsletter.</a> </h2>

      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 600, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
